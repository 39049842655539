import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { removeFilterQuery, setFilterQuery } from '../../slices/products';
import PriceInputRange from '../InputRange';
import CollapseData from '../Collapse';
import GroupRadioFilterBySize from '../GroupRadioFilterBySize';
import GroupRadioFilterByColor from '../GroupRadioFilterByColor';
import GroupCheckboxFilter from '../GroupCheckboxFilter';
import './_index.scss';

function Filter({
  className,
  hasCategory,
  filterData,
  isLoading,
  currentCodecategory,
  categoryName,
  params,
  setParams,
}) {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const { menuItems } = useSelector((state) => state.home);
  const formatedBrands = filterData?.brands?.map((brand) => {
    return {
      label: brand?.name,
      value: brand?.code,
    };
  });

  const selectedCategory = menuItems?.firstLevelItems?.find(
    (category) => category.translation?.url?.split('categories/')[1] === categoryName
  );

  const desiredKeys = ['Color', 'brands', 'prices'];

  const filteredKeys =
    filterData && Object.keys(filterData)?.filter((key) => !desiredKeys.includes(key));

  const filteredData = filteredKeys?.map((key) => ({
    key,
    value: filterData[key].map((item) => {
      return {
        label: item?.name,
        value: item?.code,
      };
    }),
  }));

  const [filterList, setFilterList] = useState([]);

  const formatedCategories = selectedCategory?.items.map((category) => {
    return {
      label: category?.label,
      value:
        category?.translation?.url?.split('/')?.[
          category?.translation?.url?.split('/')?.length - 1
        ],
    };
  });

  useEffect(() => {
    // params && Object.keys(params)?.length > 0 &&
    dispatch(setFilterQuery(params));
  }, [dispatch, params]);

  return (
    <div className={isLoading ? className + ' filter loading' : className + ' filter'}>
      <h2 className="filter-title">Filtrer par :</h2>
      <div className="filter-content">
        {hasCategory && (
          <div className="filter-content-item has-padding-left">
            <CollapseData collapseTitle={'Catégorie'} defaultValue={true} className={'in-filter'}>
              <GroupCheckboxFilter
                checkboxArray={formatedCategories}
                params={params}
                setParams={setParams}
                filterList={filterList}
                setFilterList={setFilterList}
                name={'categories'}
              />
            </CollapseData>
          </div>
        )}
        {filterData?.brands && filterData?.brands.length > 0 && (
          <div className="filter-content-item has-padding-left">
            <CollapseData collapseTitle={'Marque'} defaultValue={true} className={'in-filter'}>
              <GroupCheckboxFilter
                checkboxArray={formatedBrands}
                hasShowMore={true}
                params={params}
                setParams={setParams}
                filterList={filterList}
                setFilterList={setFilterList}
                name={'brand'}
              />
            </CollapseData>
          </div>
        )}
        {filterData?.prices && filterData?.prices?.maxPrice > 0 && (
          <div className="filter-content-item">
            <CollapseData collapseTitle={'Prix'} defaultValue={true} className={'in-filter'}>
              <PriceInputRange
                min={filterData?.prices?.minPrice}
                max={filterData?.prices?.maxPrice}
                params={params}
                setParams={setParams}
                name="price"
              />
            </CollapseData>
          </div>
        )}
        {filteredData?.map((filterItem) => (
          <div className="filter-content-item">
            <CollapseData
              collapseTitle={filterItem.key}
              defaultValue={true}
              className={'in-filter'}
            >
              <GroupRadioFilterBySize
                checkboxArray={filterItem.value}
                params={params}
                setParams={setParams}
                filterList={filterList}
                setFilterList={setFilterList}
                name={filterItem.key}
              />
            </CollapseData>
          </div>
        ))}
        {filterData?.Color && (
          <div className="filter-content-item">
            <CollapseData collapseTitle={'Couleur'} defaultValue={true} className={'in-filter'}>
              <GroupRadioFilterByColor
                data={filterData?.Color}
                params={params}
                setParams={setParams}
                filterList={filterList}
                setFilterList={setFilterList}
                name="color"
              />
            </CollapseData>
          </div>
        )}
      </div>
    </div>
  );
}

export default Filter;
