// import Im_Accueil from '../assets/img/icons/home.svg';
// import Im_Facebook from '../assets/img/icons/facebook.svg';
// import Im_Instagram from '../assets/img/icons/instagram.svg';
// import Im_Youtube from '../assets/img/icons/youtube.svg';
import location from './../assets/img/icons/location.svg';
import gift from './../assets/img/icons/gift.svg';
import help from './../assets/img/icons/help.svg';
import bookOpen from './../assets/img/icons/book-open.svg';

const DUMMY_DATA = {
  HEADERS_API: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },

  HEADER: {
    HEADER_TOP: [
      {
        id: 5,
        code: 'Homme',
        name: 'Homme',
        slug: 'homme',
        description:
          'Soluta nesciunt recusandae quam minus in veniam mollitia. Laudantium maiores quia repudiandae eligendi voluptatum ratione. Autem cumque magni error cupiditate praesentium sit. Cum reprehenderit labore est repellendus.',
        children: [
          {
            id: 29,
            name: 'Vêtements',
            slug: 'VÊTEMENTS',
            code: 'VÊTEMENTS',
            children: [
              {
                id: 43,
                name: 'Chemises',
                slug: 'Chemises',
                code: 'Chemises',
              },
              { id: 18, name: 'Pantalons', slug: 'Pantalons', code: 'Pantalons' },
              { id: 22, name: 'T-shirt/polo', slug: 'T-shirt/polo', code: 'T-shirt/polo' },
            ],
          },
          {
            id: 78,
            name: 'Chaussures',
            slug: 'CHAUSSURES',
            code: 'CHAUSSURES',
            children: [
              {
                id: 6,
                name: 'Baskets',
                slug: 'Baskets',
                code: 'Baskets',
              },
              { id: 19, name: 'Bottes', slug: 'Bottes', code: 'Bottes' },
              {
                id: 9,
                name: 'Chaussures de sport',
                slug: 'Chaussures de sport',
                code: 'Chaussures de sport',
              },
            ],
          },
        ],
      },
      {
        id: 5,
        code: 'Femme',
        name: 'Femme',
        slug: 'femme',
        description:
          'Soluta nesciunt recusandae quam minus in veniam mollitia. Laudantium maiores quia repudiandae eligendi voluptatum ratione. Autem cumque magni error cupiditate praesentium sit. Cum reprehenderit labore est repellendus.',
        children: [
          {
            id: 29,
            name: 'VÊTEMENTS',
            slug: 'VÊTEMENTS',
            code: 'VÊTEMENTS',
            children: [
              {
                id: 43,
                name: 'Chemises',
                slug: 'Chemises',
                code: 'Chemises',
              },
              { id: 18, name: 'Pantalons', slug: 'Pantalons', code: 'Pantalons' },
              { id: 22, name: 'T-shirt/polo', slug: 'T-shirt/polo', code: 'T-shirt/polo' },
            ],
          },
          {
            id: 78,
            name: 'CHAUSSURES',
            slug: 'CHAUSSURES',
            code: 'CHAUSSURES',
            children: [
              {
                id: 6,
                name: 'Baskets',
                slug: 'Baskets',
                code: 'Baskets',
              },
              { id: 19, name: 'Bottes', slug: 'Bottes', code: 'Bottes' },
              {
                id: 9,
                name: 'Chaussures de sport',
                slug: 'Chaussures de sport',
                code: 'Chaussures de sport',
              },
            ],
          },
        ],
      },
      {
        id: 5,
        code: 'Homme',
        name: 'Homme',
        slug: 'homme',
        description:
          'Soluta nesciunt recusandae quam minus in veniam mollitia. Laudantium maiores quia repudiandae eligendi voluptatum ratione. Autem cumque magni error cupiditate praesentium sit. Cum reprehenderit labore est repellendus.',
        children: [
          {
            id: 29,
            name: 'VÊTEMENTS',
            slug: 'VÊTEMENTS',
            code: 'VÊTEMENTS',
            children: [
              {
                id: 43,
                name: 'Chemises',
                slug: 'Chemises',
                code: 'Chemises',
              },
              { id: 18, name: 'Pantalons', slug: 'Pantalons', code: 'Pantalons' },
              { id: 22, name: 'T-shirt/polo', slug: 'T-shirt/polo', code: 'T-shirt/polo' },
            ],
          },
          {
            id: 78,
            name: 'CHAUSSURES',
            slug: 'CHAUSSURES',
            code: 'CHAUSSURES',
            children: [
              {
                id: 6,
                name: 'Baskets',
                slug: 'Baskets',
                code: 'Baskets',
              },
              { id: 19, name: 'Bottes', slug: 'Bottes', code: 'Bottes' },
              {
                id: 9,
                name: 'Chaussures de sport',
                slug: 'Chaussures de sport',
                code: 'Chaussures de sport',
              },
            ],
          },
        ],
      },
      {
        id: 5,
        code: 'Femme',
        name: 'Femme',
        slug: 'femme',
        description:
          'Soluta nesciunt recusandae quam minus in veniam mollitia. Laudantium maiores quia repudiandae eligendi voluptatum ratione. Autem cumque magni error cupiditate praesentium sit. Cum reprehenderit labore est repellendus.',
        children: [
          {
            id: 29,
            name: 'VÊTEMENTS',
            slug: 'VÊTEMENTS',
            code: 'VÊTEMENTS',
            children: [
              {
                id: 43,
                name: 'Chemises',
                slug: 'Chemises',
                code: 'Chemises',
              },
              { id: 18, name: 'Pantalons', slug: 'Pantalons', code: 'Pantalons' },
              { id: 22, name: 'T-shirt/polo', slug: 'T-shirt/polo', code: 'T-shirt/polo' },
            ],
          },
          {
            id: 78,
            name: 'CHAUSSURES',
            slug: 'CHAUSSURES',
            code: 'CHAUSSURES',
            children: [
              {
                id: 6,
                name: 'Baskets',
                slug: 'Baskets',
                code: 'Baskets',
              },
              { id: 19, name: 'Bottes', slug: 'Bottes', code: 'Bottes' },
              {
                id: 9,
                name: 'Chaussures de sport',
                slug: 'Chaussures de sport',
                code: 'Chaussures de sport',
              },
            ],
          },
        ],
      },
    ],
    HEADER_MIDDLE: [
      {
        id: 88,
        name: 'Collection mondiale 2022',
        link: '/collection',
      },
      {
        id: 77,
        name: 'NOUVEAUTÉ !',
        link: '/nouveauté',
        type: 'regular',
      },
      {
        id: 44,
        name: 'Bons plans',
        link: '/bons-plans',
      },
      {
        id: 11,
        name: 'Promos',
        link: '/categories/promos',
        type: 'special',
      },
    ],
    HEADER_BOTTOM: [
      {
        id: 88,
        name: 'Magasins',
        link: '/shops',
        icon: location,
      },
      {
        id: 77,
        name: 'E-catalogue',
        link: '/e-catalogue',
        icon: bookOpen,
      },
      {
        id: 44,
        name: 'Carte cadeau',
        link: '/carte-cadeau',
        icon: gift,
      },
      {
        id: 11,
        name: 'Aidez-moi',
        link: '/aidez-moi',
        icon: help,
      },
    ],
  },
};
export default DUMMY_DATA;
