import React from "react";
import "./_index.scss"

function index() {
  return (
    <div class="cubes">
      <div class="bounce">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  );
}

export default index;
