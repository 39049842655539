import React, { useState } from 'react';
import './_index.scss';

function InputRadioFilterBySize({ data, isChecked, handleInputChange }) {
  const { label, value } = data;
  return (
    <label htmlFor={value} className={'input-radio-filter-by-size'}>
      <div className={isChecked ? 'input-radio active' : 'input-radio'}>{label}</div>
      <input
        onChange={handleInputChange}
        type="checkbox"
        id={value}
        name={value}
        value={value}
        checked={isChecked}
      />
    </label>
  );
}

export default InputRadioFilterBySize;
