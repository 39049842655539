import React, { useState } from 'react';
import './_index.scss';

function InputRadioFilterByColor({ data, isChecked, handleInputChange }) {
  const { name, code } = data;
  return (
    <label htmlFor={code} className={'input-radio-filter-by-color'}>
      <div className={isChecked ? 'input-radio-color active' : 'input-radio-color'}>
        <div className="color" style={{ background: `#${code}` }}></div>
        <div className="color-name">{name}</div>
      </div>
      <input onClick={handleInputChange} type="checkbox" id={code} name={code} value={code} />
    </label>
  );
}

export default InputRadioFilterByColor;
