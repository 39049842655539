import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import './_index.scss';

function GroupMenuDesktop({ title, thirdLevelItems, linkThirdLevel, onClick }) {
  const [sortThirdLevelItems, setSortThirdLevelItems] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    let thirdItems = [];
    thirdItems = thirdLevelItems.slice().sort((i1, i2) => (i1.position < i2.position ? -1 : 1));
    setSortThirdLevelItems(thirdItems);
  }, [thirdLevelItems]);

  return (
    <div className="group-menu-desktop">
      {title && (
        <NavLink
          className="title"
          to={`${linkThirdLevel ? linkThirdLevel : ''}`}
          onClick={() => {
            onClick();
          }}
        >
          {title}
        </NavLink>
      )}
      <ul className="content">
        {sortThirdLevelItems?.map((thirdLevelItem, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                onClick();
              }}
            >
              <Link
                to={
                  thirdLevelItem?.translation?.url?.includes('categories')
                    ? `/categories${thirdLevelItem?.translation?.url?.split('categories')[1]}`
                    : thirdLevelItem?.translation?.url
                }
              >
                {thirdLevelItem.label}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default GroupMenuDesktop;
