import React, { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import axios from '../utilities/axios';
import axiosForPatch from '../utilities/axiosForPatch';
import LoadingScreen from '../components/LoadingScreen';
import { useState } from 'react';
import { toast } from 'react-toastify';

const initialAuthState = {
  customerId: null,
  filtredUser: null,
  isAuthenticated: false,
  isInitialised: false,
};
const isValidToken = (token) => {
  if (!token) {
    return false;
  }
  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decoded.exp > currentTime;
};

export const tokenExpired = (exp, iat) => {
  // eslint-disable-next-line prefer-const

  let expiredTimer;
  const timeLeft = `${exp - iat}000`;
  clearTimeout(expiredTimer);
  expiredTimer = setTimeout(() => {
    toast.warn('Votre session est expiré', {
      onClose: () => {
        localStorage.removeItem('token');
        window.location.href = 'http://localhost:3000/';
      },
    });
  }, timeLeft);
};

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('token', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosForPatch.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const decoded = jwtDecode(accessToken);
    const { exp, iat } = decoded;
    tokenExpired(exp, iat);
  } else {
    localStorage.removeItem('token');
    localStorage.removeItem('tokenValue');

    delete axios.defaults.headers.common.Authorization;
    delete axiosForPatch.defaults.headers.common.Authorization;
  }
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, filtredUser } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        filtredUser,
      };
    }
    case 'LOGIN': {
      const { customerId } = action.payload;

      return {
        ...state,
        customerId,
        isAuthenticated: true,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        filtredUser: null,
      };
    }
    case 'FORGOT': {
      return {
        ...state,
        isAuthenticated: false,
      };
    }
    case 'RESET': {
      return {
        ...state,
      };
    }
    case 'SIGNUP': {
      return {
        ...state,
        isAuthenticated: true,
      };
    }
    default: {
      return { ...state };
    }
  }
};
const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  forgetPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  signup: () => Promise.resolve(),
});
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const [filtredUserData, setFiltredUserData] = useState(initialAuthState.filtredUser);

  const login = async (values) => {
    const response = await axios.post(`api/v2/shop/authentication-token`, values);
    const { token, customer } = response.data;
    localStorage.setItem('token', token);

    // setSession(token);
    dispatch({
      type: 'LOGIN',
      payload: {
        customerId: customer,
      },
    });
    await initialise();
  };

  const signup = async (values) => {
    const response = await axios.post('/api/v2/shop/customers', values);
    // const { token } = response.data.payload;
    // setSession(token);
    // dispatch({
    //   type: 'SIGNUP',
    //   payload: {},
    // });
  };

  const logout = () => {
    setSession(null);
    setFiltredUserData(null);
    localStorage.removeItem('token');
    // localStorage.removeItem('tokenValue');
    localStorage.removeItem('wishListToken');
    // localStorage.clear();
    dispatch({ type: 'LOGOUT' });
  };

  const forgetPassword = async (credentiel) => {
    const response = await axios.post(`/api/v2/shop/reset-password-requests`, {
      ...credentiel,
      locale: 'en_US',
    });
    dispatch({
      type: 'FORGOT',
    });
    return response;
  };

  const resetPassword = async (data) => {
    const { resetPasswordToken, newPassword, confirmNewPassword } = data;
    const response = await axiosForPatch.patch(
      `/api/v2/shop/reset-password-requests/${resetPasswordToken}`,
      { newPassword, confirmNewPassword }
    );
    dispatch({
      type: 'RESET',
    });
    return response;
  };

  const initialise = async () => {
    try {
      const token = window.localStorage.getItem('token');
      const decoded = jwtDecode(token);
      if (token && isValidToken(token)) {
        const response = await axios.get(`/api/v2/shop/customers/${decoded.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const user = response.data;
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: true,
            filtredUser: user,
          },
        });
        setFiltredUserData(user);
        setSession(token);
      } else {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            filtredUser: null,
          },
        });
      }
    } catch (err) {
      dispatch({
        type: 'INITIALISE',
        payload: {
          isAuthenticated: false,
          filtredUser: null,
        },
      });
    }
  };

  const updateFiltredUser = (newValue) => {
    setFiltredUserData(newValue);
  };

  useEffect(() => {
    initialise();
  }, []);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        signup,
        forgetPassword,
        resetPassword,
        updateFiltredUser,
        filtredUserData,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
