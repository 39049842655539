import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
  productsHighlightSlug: '',
  allProductsHighlight: [],
  allProductsHighlightStatus: 'idle',
  allProductsHighlightSuccess: '',
  allProductsHighlightError: '',
  productsHighlightBySlug: {},
  productsHighlightBySlugStatus: 'idle',
  currentProductId: '',
  allProductsHighlightCopy: [],
};

export const getAllProductsHighlight = createAsyncThunk(
  'productsHighlight/getAllProductsHighlight',
  async (query, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/products-highlight`, { params: query });
      data = await response.data;
      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getProductsHighlightBySlug = createAsyncThunk(
  'productsHighlight/getProductsHighlightBySlug',
  async (slug, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/product-highlight/${slug}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name: 'productsHighlight',
  initialState,
  reducers: {
    getCurrentProductIdFromProductHighlight: (state, action) => {
      state.currentProductId = action.payload;
    },
    
    editProductHighlightWishState: (state) => {
      state.allProductsHighlight?.map((allProducts) => {
        allProducts.activeProducts?.map((product) => {
          if (product.id === state.currentProductId) {
            product.wishlist = !product.wishlist;
          }
        });
      });
    },

    editProductHighlightBySlugWishState: (state) => {
      state.productsHighlightBySlug.activeProducts?.map((product) => {
        if (product.id === state.currentProductId) {
          product.wishlist = !product.wishlist;
        }
      });
    },
  },

  extraReducers: {
    [getAllProductsHighlight.pending]: (state) => {
      state.allProductsHighlightStatus = 'loading';
    },
    [getAllProductsHighlight.fulfilled]: (state, action) => {
      state.allProductsHighlightStatus = 'succeeded';
      state.allProductsHighlight = action.payload;
    },
    [getAllProductsHighlight.rejected]: (state) => {
      state.allProductsHighlightStatus = 'failed';
    },
    [getProductsHighlightBySlug.pending]: (state) => {
      state.productsHighlightBySlugStatus = 'loading';
    },
    [getProductsHighlightBySlug.fulfilled]: (state, action) => {
      state.productsHighlightBySlugStatus = 'succeeded';
      state.productsHighlightBySlug = action.payload;
    },
    [getProductsHighlightBySlug.rejected]: (state) => {
      state.productsHighlightBySlugStatus = 'failed';
    },
  },
});

export const {
  getCurrentProductIdFromProductHighlight,
  editProductHighlightWishState,
  editProductHighlightBySlugWishState,
} = slice.actions;
export const reducer = slice.reducer;

export default slice;
