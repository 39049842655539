import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from '../utilities/axios';

const initialState = {
  getShippingMethodStatus: 'idle',
  getShippingMethodSuccess: '',
  getShippingMethodError: '',
  shippingMethod: [],
};

export const getShippingMethod = createAsyncThunk(
  'shipping/getShippingMethod',
  async (_, thunkAPI) => {
    let data;
    try {
      const response = await axios.get(`/api/v2/shop/shipping-methods`);

      data = await response.data;

      if (response.status === 200) {
        // thunkAPI.dispatch(addItemToBasket(basketData));
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(toast.error(message));

      return thunkAPI.rejectWithValue(message);
    }
  }
);

const slice = createSlice({
  name: 'basket',
  initialState,
  reducers: {},
  extraReducers: {
    [getShippingMethod.pending]: (state) => {
      state.getShippingMethodStatus = 'loading';
    },
    [getShippingMethod.fulfilled]: (state, action) => {
      state.getShippingMethodStatus = 'succeeded';
      // state.getShippingMethodSuccess = action.payload.message;
      state.shippingMethod = action.payload;
      // state.meta = action.payload.meta;
    },
    [getShippingMethod.rejected]: (state, action) => {
      state.getShippingMethodStatus = 'failed';
      state.getShippingMethodError = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
