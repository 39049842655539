import React from 'react';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import './_index.scss';

function CustomGroupRadio({ label, value, setValue, radioArray, className }) {
  const RadioButton = ({ checked }) => (
    <svg width="17px" height="17px" viewBox="0 0 24 24" fontSize="17px">
      <circle cx="50%" cy="50%" r="11px" stroke="#707070" stroke-width="1px" fill="none" />
      {checked && <circle cx="50%" cy="50%" r="7px" fill="#CC0D0D" />}
    </svg>
  );

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div className={className ? className + ' custom-group-radio' : 'custom-group-radio'}>
      <label className="custom-group-radio-label">{label}</label>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="label"
        name="radio-buttons-group"
        value={value}
        onChange={handleChange}
        row
        sx={{ gap: '33px' }}
      >
        {radioArray.map((el, key) => {
          return (
            <FormControlLabel
              key={key}
              value={el.value}
              control={<Radio icon={<RadioButton />} checkedIcon={<RadioButton checked />} />}
              label={<span className="label-checkbox">{el.label}</span>}
            />
          );
        })}
      </RadioGroup>
    </div>
  );
}

export default CustomGroupRadio;
