import React, { useEffect, useState } from 'react';
import { Drawer, IconButton } from '@mui/material';
import CollapseData from '../../Collapse';
import DirectLink from '../../DirectLink';
import close from './../../../assets/img/icons/close.svg';
import DUMMY_DATA from './../../../utilities/constants';
import { useSelector } from 'react-redux';
import StyleToObject from 'style-to-object';
import './_index.scss';
import { Link } from 'react-router-dom';

function MenuModal({ id, open, handleClose, data }) {
  const menuItems = useSelector((state) => state.home.menuItems);

  const [listWithChildren, setListWithChildren] = useState([]);
  const [listWithoutChildren, setListWithoutChildren] = useState([]);
  const [sortedMenuChildren, setSortedMenuChildren] = useState([]);

  useEffect(() => {
    let listWithItems = [];
    let listWithoutItems = [];
    let sortedMenuItems = [];

    sortedMenuItems = menuItems.firstLevelItems
      ?.slice()
      .sort((i1, i2) => (i1.position < i2.position ? -1 : 1));

    menuItems.firstLevelItems?.map((menuItem) =>
      menuItem.items.length === 0 ? listWithoutItems.push(menuItem) : listWithItems.push(menuItem)
    );

    setSortedMenuChildren(sortedMenuItems);
    setListWithoutChildren(listWithoutItems);
    setListWithChildren(listWithItems);
  }, [menuItems]);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={() => {
        handleClose(id);
      }}
      className="modal"
    >
      <div className="modal-wrapper">
        <div className="menu-modal-top">
          <div className="modal-header">
            <p className="modal-title">MENU</p>
            <IconButton className="close" onClick={() => handleClose(id)}>
              <img src={close} alt="close" />
            </IconButton>
          </div>
          <div className="modal-content">
            {sortedMenuChildren?.map((item, index) =>
              listWithChildren?.includes(item) ? (
                <div className="modal-content__top" key={index}>
                  {item.label !== 'Marques' ? (
                    <CollapseData collapseTitle={item.label}>
                      {item.items
                        ?.slice()
                        .sort((i1, i2) => (i1.position < i2.position ? -1 : 1))
                        .map((child, index) => (
                          <CollapseData
                            collapseTitle={child.label}
                            key={index}
                            className="nested-collapse"
                          >
                            {child.items
                              ?.slice()
                              .sort((i1, i2) => (i1.position < i2.position ? -1 : 1))
                              .map((child, index) => (
                                <p
                                  key={index}
                                  className="nested-nested-collapse"
                                  onClick={() => handleClose(id)}
                                >
                                  <Link
                                    to={
                                      child.translation?.url.includes('categories')
                                        ? '/categories' +
                                          child.translation?.url?.split('/categories')[1]
                                        : child.translation?.url
                                    }
                                  >
                                    {child.label}
                                  </Link>
                                </p>
                              ))}
                          </CollapseData>
                        ))}
                    </CollapseData>
                  ) : (
                    <CollapseData collapseTitle={item.label}>
                      {item.items
                        ?.slice()
                        ?.sort((i1, i2) => (i1.position < i2.position ? -1 : 1))
                        .map((child, index) => (
                          <p
                            key={index}
                            className="nested-nested-collapse"
                            onClick={() => handleClose(id)}
                          >
                            <Link
                              to={
                                child.translation?.url?.includes('categories')
                                  ? '/categories' + child.translation?.url?.split('/categories')[1]
                                  : child.translation?.url
                              }
                            >
                              {child.label}
                            </Link>
                          </p>
                        ))}
                    </CollapseData>
                  )}
                </div>
              ) : listWithoutChildren?.includes(item) ? (
                <div className="modal-content__middle" key={index}>
                  <DirectLink
                    key={item}
                    title={item.label}
                    style={StyleToObject(item.style)}
                    link={
                      item.translation.url?.includes('categories')
                        ? '/categories' + item.translation.url?.split('/categories')[1]
                        : item.translation.url
                    }
                    onClose={() => {
                      handleClose(id);
                    }}
                  />
                </div>
              ) : (
                <></>
              )
            )}
          </div>
        </div>
        <div className="menu-modal-bottom">
          <div className="menu-bottom__wrapper">
            {DUMMY_DATA.HEADER.HEADER_BOTTOM.map((item) => (
              <DirectLink
                key={item.id}
                title={item.name}
                link={item.link}
                icon={item.icon}
                onClose={() => {
                  handleClose(id);
                }}
              />
            ))}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default MenuModal;
