import { combineReducers } from '@reduxjs/toolkit';
import { reducer as modalsReducer } from '../slices/modals';
import { reducer as brandsReducer } from '../slices/brands';
import { reducer as productsHighlightReducer } from '../slices/productsHighlight';
import { reducer as productsReducer } from '../slices/products';
import { reducer as shopsReducer } from '../slices/shops';
import { reducer as homeReducer } from '../slices/home';
import { reducer as orderReducer } from '../slices/order';
import { reducer as reviewsReducer } from '../slices/reviews';
import { reducer as paymentReducer } from '../slices/payment';
import { reducer as shippingReducer } from '../slices/shipping';
import { reducer as addressesReducer } from '../slices/addresses';
import { reducer as customerReducer } from '../slices/customer';
import { reducer as wishListReducer } from '../slices/wishList';

const combinedReducer = combineReducers({
  modals: modalsReducer,
  brands: brandsReducer,
  productsHighlight: productsHighlightReducer,
  products: productsReducer,
  order: orderReducer,
  shops: shopsReducer,
  home: homeReducer,
  reviews: reviewsReducer,
  payment: paymentReducer,
  shipping: shippingReducer,
  addresses: addressesReducer,
  wishList: wishListReducer,
  customer: customerReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
