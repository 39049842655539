import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import { toast } from 'react-toastify';
import jwtDecode from 'jwt-decode';

const initialState = {
  customerState: '',
  editCustomerPersonnalInformationStatus: 'idle',
  editPasswordSucess: false,
};

export const editCustomerPersonnalInformation = createAsyncThunk(
  'customer/editCustomerPersonnalInformation',
  async (values, thunkAPI) => {
    let data;
    const token = window.localStorage.getItem('token');
    const decoded = jwtDecode(token);

    try {
      const response = await axios.put(`/api/v2/shop/customers/${decoded?.id}`, {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        gender: values.gender,
        email: values.email,
        subscribedToNewsletter: values.subscribedToNewsletter,
      });
      data = await response.data;
      if (response.status === 200 || 201) {
        thunkAPI.dispatch(toast.success('Le profil est mis à jour avec succès'));
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

export const editCustomerPassword = createAsyncThunk(
  'customer/editCustomerPassword',
  async (values, thunkAPI) => {
    let data;
    const token = window.localStorage.getItem('token');
    const decoded = jwtDecode(token);

    try {
      const response = await axios.put(`/api/v2/shop/customers/${decoded.id}/password`, {
        newPassword: values.newPassword,
        currentPassword: values.currentPassword,
        confirmNewPassword: values.confirmPassword,
      });
      data = response.data;
      let message = toast.success('Le mot de passe est mis à jour avec succès');
      let navigateToInfo = true;
      if (response.status === 200 || 201) {
        return { data, message, navigateToInfo };
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message = error?.detail.toString();
      thunkAPI.dispatch(toast.error(message));
    }
  }
);

const slice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setNewPeronnalInformation: (state, action) => {
      state.allCustomerAddresses = state.allCustomerAddresses.filter(
        (address) => address.id !== action.payload
      );
    },
    ResetSuccessMessage: (state) => {
      state.editPasswordSucess = false;
    },
  },
  extraReducers: {
    [editCustomerPersonnalInformation.pending]: (state) => {
      state.editCustomerPersonnalInformationStatus = 'loading';
    },
    [editCustomerPersonnalInformation.fulfilled]: (state) => {
      state.editCustomerPersonnalInformationStatus = 'succeeded';
    },
    [editCustomerPersonnalInformation.rejected]: (state) => {
      state.editCustomerPersonnalInformationStatus = 'failed';
    },
    [editCustomerPassword.pending]: (state) => {
      state.customerState = 'loading';
    },
    [editCustomerPassword.fulfilled]: (state, action) => {
      state.editPasswordSucess = action.payload.navigateToInfo;
      state.customerState = 'succeeded';
    },
    [editCustomerPassword.rejected]: (state) => {
      state.customerState = 'failed';
    },
  },
});

export const reducer = slice.reducer;
export const { ResetSuccessMessage } = slice.actions;

export default slice;
