import React, { useState } from 'react';
import { Box, IconButton, Modal } from '@mui/material';
import close from './../../../assets/img/icons/close.svg';
import './_index.scss';

function ResetPasswordModal({ id, open, handleClose, data }) {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '3px 6px 20px #00000029',
    border: 'none',
  };

  //   Modal width
  const modalWidth = document.documentElement;
  if (data?.displaySuccessMessage) modalWidth.style.setProperty('--modalWidth', '786px');
  else modalWidth.style.setProperty('--modalWidth', '565px');

  // Modal Height
  const modalHeight = document.documentElement;
  if (data?.displaySuccessMessage) modalHeight.style.setProperty('--modalHeight', '162px');
  else modalWidth.style.setProperty('--modalHeight', '114px');

  return (
    <Modal
      open={open}
      onClose={() => handleClose(id)}
      aria-labelledby="reset-password-title"
      aria-describedby="reset-password-description"
      className="reset-password"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <div className="reset-password-wrapper">
          <IconButton className="close" onClick={() => handleClose(id)}>
            <img src={close} alt="close" />
          </IconButton>

          {data?.displaySuccessMessage ? (
            <div className="modal-content">
              <p className="success-message">
                L’e-mail de réinitialisation du mot de passe a été envoyé.
              </p>
              <p className="message-text">
                Un e-mail de réinitialisation de mot de passe a été envoyé à ladresse e-mail de
                votre compte, mais cela peut prendre plusieurs minutes avant qu’il ne s’affiche dans
                votre boîte de réception. Veuillez patienter au moins 10 minutes avant de tenter une
                autre réinitialisation.
              </p>{' '}
            </div>
          ) : (
            <div className="modal-content">
              <p className="error-message">ATTENTION !</p>
              <p className="message-text">
                L'adresse que vous avez fournie ne correspond pas à un compte existant.{' '}
              </p>{' '}
            </div>
          )}
        </div>
      </Box>
    </Modal>
  );
}

export default ResetPasswordModal;
