import React from 'react';
import { useSelector } from 'react-redux';
import InputRadioFilterBySize from '../InputRadioFilterBySize';
import './_index.scss';

function GroupRadioFilterBySize({
  name,
  checkboxArray,
  filterList,
  setFilterList,
  params,
  setParams,
}) {
  let allValues = [];
  const handleInputChange = (event) => {
    const { value } = event.target;
    const newFilterList = filterList.includes(value)
      ? filterList.filter((item) => item !== value)
      : [...filterList, value];
    allValues.push(newFilterList);
    const selectedValues = checkboxArray
      .map((item) => item.value)
      .filter((value) => newFilterList.includes(value));

    setFilterList(selectedValues);
    setParams({
      ...params,
      [name]: selectedValues,
    });
  };

  return (
    <div className="group-checkbox-filter-by-size">
      {checkboxArray.map((checkbox, index) => (
        <InputRadioFilterBySize
          data={checkbox}
          key={index}
          isChecked={params[name]?.includes(checkbox.value)}
          handleInputChange={handleInputChange}
        />
      ))}
    </div>
  );
}

export default GroupRadioFilterBySize;
