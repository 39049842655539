import React from 'react';
import InputRadioFilterByColor from '../InputRadioFilterByColor';
import './_index.scss';

function GroupRadioFilterByColor({ data, filterList, setFilterList, params, setParams, name }) {
  const handleInputChange = (event) => {
    const { value } = event.target;
    const newFilterList = filterList.includes(value)
      ? filterList.filter((item) => item !== value)
      : [...filterList, value];
    const selectedValues = data
      .map((item) => item.code)
      .filter((value) => newFilterList.includes(value));

    setFilterList(selectedValues);
    setParams({
      ...params,
      [name]: selectedValues,
    });
  };
  return (
    <div className="group-checkbox-filter-by-color">
      {data.map((color, index) => (
        <InputRadioFilterByColor
          data={color}
          key={index}
          isChecked={params[name]?.includes(color.code)}
          handleInputChange={handleInputChange}
        />
      ))}
    </div>
  );
}

export default GroupRadioFilterByColor;
