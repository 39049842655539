import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from '../../slices/modals';
import useAuth from '../../hooks/useAuth';
import ModalExample from '../Modals/ModalExample';
import MenuModal from '../Modals/MenuModal';
import BasketModal from '../Modals/BasketModal';
import SearchModal from '../Modals/SearchModal';
import FilterModal from '../Modals/FilterModal';
import ReviewModal from '../Modals/ReviewModal';
import PreviewProductSheetModal from '../Modals/PreviewProductSheetModal';
import ResetPasswordModal from '../Modals/ResetPasswordModal/_index';
import DeleteAddressModal from '../Modals/DeleteAddress';

const ModalsProvider = (props) => {
  const { isAuthenticated } = useAuth();
  const { modals } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const modalState = (id, key) => {
    const res = modals.find((modal) => modal.id === id);
    return res[key];
  };
  const handleClose = (id) => {
    dispatch(closeModal(id));
  };
  return (
    <>
      <ModalExample
        id="modal-example"
        open={modalState('modal-example', 'open')}
        data={modalState('modal-example', 'data')}
        handleClose={handleClose}
      />
      <MenuModal
        id="menu-modal"
        open={modalState('menu-modal', 'open')}
        data={modalState('menu-modal', 'data')}
        handleClose={handleClose}
      />
      <BasketModal
        id="basket-modal"
        open={modalState('basket-modal', 'open')}
        data={modalState('basket-modal', 'data')}
        handleClose={handleClose}
      />
      <SearchModal
        id="search-modal"
        open={modalState('search-modal', 'open')}
        data={modalState('search-modal', 'data')}
        handleClose={handleClose}
      />
      <FilterModal
        id="filter-modal"
        open={modalState('filter-modal', 'open')}
        data={modalState('filter-modal', 'data')}
        handleClose={handleClose}
      />
      <ReviewModal
        id="review-modal"
        open={modalState('review-modal', 'open')}
        data={modalState('review-modal', 'data')}
        handleClose={handleClose}
      />
      <PreviewProductSheetModal
        id="preview-product-sheet-modal"
        open={modalState('preview-product-sheet-modal', 'open')}
        data={modalState('preview-product-sheet-modal', 'data')}
        handleClose={handleClose}
      />
      <ResetPasswordModal
        id="reset-password-modal"
        open={modalState('reset-password-modal', 'open')}
        data={modalState('reset-password-modal', 'data')}
        handleClose={handleClose}
      />
      <DeleteAddressModal
        id="delete-address-modal"
        open={modalState('delete-address-modal', 'open')}
        data={modalState('delete-address-modal', 'data')}
        handleClose={handleClose}
      />
    </>
  );

  //  isAuthenticated ?

  // : null;
};

export default ModalsProvider;
