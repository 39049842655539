import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
// import snackbar from '../utilities/snackbar';

const initialState = {
  allBrands: [],
  allBrandsStatus: 'idle',

  productByBrand: {},
  meta: {},
  allProductsByBrandStatus: 'idle',
  allProductsByBrandSuccess: '',
  allProductsByBrandError: '',
};

export const getAllBrands = createAsyncThunk('brands/getAllBrands', async (query, thunkAPI) => {
  let data;
  try {
    const response = await axios.get(`/api/v2/shop/brand`, { params: query });

    data = await response.data;
    if (response.status === 200) {
      return data;
    }

    throw new Error(response.statusText);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

const slice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},
  extraReducers: {
    [getAllBrands.pending]: (state) => {
      state.allBrandsStatus = 'loading';
    },
    [getAllBrands.fulfilled]: (state, action) => {
      state.allBrandsStatus = 'succeeded';
      state.allBrands = action.payload;
    },
    [getAllBrands.rejected]: (state) => {
      state.allBrandsStatus = 'failed';
    },
  },
});

export const reducer = slice.reducer;
export const { reset, setActivityUpdate, deleteActivityUpdate, setCurrentActitvity } =
  slice.actions;

export default slice;
