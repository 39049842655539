import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Drawer, IconButton } from '@mui/material';
import Button from '../../Button';
import ProductBasketCard from '../../ProductBasketCard';
import close from './../../../assets/img/icons/close.svg';
import track from './../../../assets/img/icons/track.svg';
import WindowDimensions from '../../../utilities/windowDimenssions';
import { useNavigate } from 'react-router-dom';
import priceFormat from '../../../utilities/priceFormat';
import { toast } from 'react-toastify';
import './_index.scss';

function BasketModal({ id, open, handleClose, data }) {
  const { width, height } = WindowDimensions();
  const isMobile = width < 768;

  const { basket, getBasketStatus } = useSelector((state) => state.order);
  const navigate = useNavigate();

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        handleClose(id);
      }}
      className="modal"
    >
      <div className="basket-modal-wrapper">
        <div className="basket-modal-top">
          <div className="modal-header">
            <IconButton className="close" onClick={() => handleClose(id)}>
              <img src={close} alt="close" />
            </IconButton>
            <p className="modal-title">Mon panier</p>
          </div>
          <Divider />

          <div className="free-shipping">
            <div className="shipping-icon">
              <img src={track} alt="track" />
            </div>
            <div className="free-shipping-content">
              <p className="title">LIVRAISON GRATUITE !</p>
              <p className="description">Livraison gratuite à partir de 5.000.00 DA d’achat</p>
            </div>
          </div>
          <Divider />

          <div className="modal-content">
            {getBasketStatus === 'loading' ? (
              <>
                <ProductBasketCard isLoading={true} />
                <ProductBasketCard isLoading={true} />
              </>
            ) : basket?.items?.length > 0 ? (
              basket?.items?.map((product, index) => (
                <ProductBasketCard
                  key={index}
                  data={product}
                  isLoading={false}
                  closeModal={() => handleClose(id)}
                />
              ))
            ) : (
              <p>Votre panier est actuellement vide.</p>
            )}
          </div>
        </div>
        <div className="basket-modal-bottom">
          {!isMobile && <Divider />}
          <div className="basket-bottom__wrapper">
            <div className="sub-total">
              <p className="title">Total partiel :</p>
              <p className="price-total">{basket?.formattedTotal}</p>
            </div>
            <div className="buttons-wrapper">
              <Button
                text="Finaliser ma commande"
                onClick={() => {
                  basket?.items?.length === 0
                    ? toast.warn('Votre panier est vide')
                    : navigate('/tunnel-achat');
                  handleClose(id);
                }}
              />
              {!isMobile && (
                <Button
                  className="black"
                  text="Voir mon panier"
                  onClick={() => {
                    navigate('/panier');
                    handleClose(id);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default BasketModal;
