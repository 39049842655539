import './_index.scss';

const CustomInput = ({ field, form, label, className, ...props }) => {
  return (
    <div className={className ? className + ' custom-input' : 'custom-input'}>
      <label className="input-label" htmlFor={field.name}>
        {label}
      </label>
      <div className="input-wrapper">
        <input {...field} {...props} id={field.name} />
        {form.touched[field.name] && form.errors[field.name] && (
          <p className="input-error">{form.errors[field.name]}</p>
        )}
      </div>
    </div>
  );
};

export default CustomInput;
