import React from 'react';
import './_index.scss';

const LoadingScreen = () => {
  return (
    <div className="loading-screen">
      <span className="loader"></span>
    </div>
  );
};

export default LoadingScreen;
