import React from 'react';
import ReactDOM from 'react-dom';
import { enableES5 } from 'immer';
import { Provider } from 'react-redux';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';
import { createRoot } from 'react-dom/client';
import ErrorBoundary from './ErrorBoundary';

const root = createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ErrorBoundary>
      <App />
    </ErrorBoundary>
  </Provider>
);

serviceWorker.unregister();
