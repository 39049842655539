import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const OrderGuard = ({ children }) => {
  const { basket } = useSelector((state) => state.order);

  if (basket?.items?.length === 0) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default OrderGuard;
