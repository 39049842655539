import React, { useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Facebook from './../../assets/img/icons/facebook-2.svg';
import Instagram from './../../assets/img/icons/instagram.svg';
import Twitter from './../../assets/img/icons/twitter-black-icon.svg';
import LinkedIn from './../../assets/img/icons/linkedin.svg';
import Youtube from './../../assets/img/icons/youtube.svg';
import home from './../../assets/img/icons/home.svg';
import heart from './../../assets/img/icons/heart.svg';
import menuGrid from './../../assets/img/icons/menu-grid.svg';
import userCertification from './../../assets/img/icons/user-certification.svg';
import shoppingBag from './../../assets/img/icons/shopping-bag.svg';
import logo from './../../assets/img/icons/logo-1.png';
import { openModal } from '../../slices/modals';
import { useDispatch, useSelector } from 'react-redux';
import { getFooterItems } from '../../slices/home';
import './_index.scss';

const Footer = () => {
  let currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const { basket } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getFooterItems());
  }, []);

  const footerItems = useSelector((state) => state.home.footerItems);
  const socialMediaItems = useSelector((state) => state.home.socialMediaItems);

  return (
    <div className="footer">
      {/* Footer Mobile */}
      <div className="footer-mobile container">
        <NavLink to={'/'} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
          <img src={home} alt="home" />
        </NavLink>
        <NavLink to={'/profile'} className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
          <img src={userCertification} alt="user certification" />
        </NavLink>
        <NavLink
          to={'/categories'}
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          <img src={menuGrid} alt="menu grid" />
        </NavLink>
        <NavLink
          to={'/profile/favorites'}
          className={({ isActive }) => (isActive ? 'active' : 'inactive')}
        >
          <img src={heart} alt="heart" />
        </NavLink>
        <div className="basket" onClick={() => dispatch(openModal('basket-modal'))}>
          <div className="basket-count">{basket?.items?.length || 0}</div>
          <img src={shoppingBag} alt="shopping bag" />
        </div>
      </div>

      {/* Footer Desktop */}
      <>
        <div className="footer-content">
          <div className="footer-content-wrapper container">
            <div className="footer-left">
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
              </div>
              <div className="social-media">
                <p className="title">SUIVEZ-NOUS SUR NOS RÉSEAUX SOCIAUX !</p>
                <div className="social-media-wrapper">
                  {socialMediaItems?.map((socialItem, index) => (
                    <React.Fragment key={index}>
                      {
                        {
                          'Facebook': (
                            <Link to={socialItem.url} target="_blank">
                              <img
                                src={Facebook}
                                alt={socialItem.name}
                                className="footer-social-icons"
                              />
                            </Link>
                          ),
                          'Twitter': (
                            <Link to={socialItem.url} target="_blank">
                              <img
                                src={Twitter}
                                alt={socialItem.name}
                                className="footer-social-icons"
                              />
                            </Link>
                          ),
                          'LinkedIn': (
                            <Link to={socialItem.url} target="_blank">
                              <img
                                src={LinkedIn}
                                alt={socialItem.name}
                                className="footer-social-icons"
                              />
                            </Link>
                          ),
                          'Youtube': (
                            <Link to={socialItem.url} target="_blank">
                              <img
                                src={Youtube}
                                alt={socialItem.name}
                                className="footer-social-icons"
                              />
                            </Link>
                          ),
                          'Instagram': (
                            <Link to={socialItem.url} target="_blank">
                              <img
                                src={Instagram}
                                alt={socialItem.name}
                                className="footer-social-icons"
                              />
                            </Link>
                          ),
                        }[socialItem.name]
                      }
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="footer-information">
              {footerItems?.map((item, index) => {
                return (
                  <div key={index} className="footer-information-link">
                    <p className="title">{item.description}</p>
                    <div className="all-links">
                      {item.footerListDetails.map((detail, index) => {
                        return (
                          <Link key={index} className="link" to={detail.url}>
                            {detail.name}
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="footer-copyright">
          <p>
            © {currentYear + ' '}
            <Link to="http://www.dotit-corp.com" target={'_blank'}>
              DOTIT
            </Link>
            . Tous droits réservés.
          </p>
        </div>
      </>
    </div>
  );
};

export default Footer;
