import React from 'react';
import './_index.scss';

const CustomTextarea = ({ field, form, label, ...props }) => {
  const handleChange = (e) => {
    if (field) {
      field.onChange(e);
    } else {
      props.setNotes(e.target.value);
    }
  };
  return (
    <div className="custom-textarea">
      <label htmlFor={field?.name} className="textarea-label">
        {label}
      </label>
      <textarea
        id={field?.name}
        {...field}
        {...props}
        onChange={(e) => {
          handleChange(e);
        }}
      />
      {form?.touched[field?.name] && form?.errors[field.name] && (
        <p className="textarea-error">{form.errors[field.name]}</p>
      )}
    </div>
  );
};

export default CustomTextarea;
