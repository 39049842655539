import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import StyleToObject from 'style-to-object';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import { openModal } from '../../slices/modals';
import { getMenuItems, getSocialMediaItems } from '../../slices/home';
import { createBasket, getBasket } from '../../slices/order';
import { searchProducts } from '../../slices/products';
import WindowDimensions from '../../utilities/windowDimenssions';
import useDebounce from '../../hooks/useDebounce';
import MenuPopoverDesktop from '../../components/MenuPopoverDesktop';
import ProductSearchCard from '../../components/ProductSearchCard';
import useAuth from '../../hooks/useAuth';
import phone from './../../assets/img/icons/phone.svg';
import locationImg from './../../assets/img/icons/location.svg';
import userCertification from './../../assets/img/icons/user-certification.svg';
import shoppingBag from './../../assets/img/icons/shopping-bag.svg';
import searchIcon from './../../assets/img/icons/search.svg';
import commande from './../../assets/img/icons/Commande.svg';
import logo from './../../assets/img/icons/logo-1.png';
import menu from './../../assets/img/icons/menu.png';
import favoris from './../../assets/img/icons/Favoris2.svg';
import Facebook from './../../assets/img/icons/facebook.svg';
import Instagram from './../../assets/img/icons/instagram-red-icon.svg';
import Twitter from './../../assets/img/icons/twitter.svg';
import deconnexion from './../../assets/img/icons/Deconnexion.svg';
import './_index.scss';

const Header = () => {
  const tokenValue = localStorage.getItem('tokenValue');

  const dispatch = useDispatch();
  useEffect(() => {
    !tokenValue && dispatch(createBasket());
  }, [tokenValue, dispatch]);

  const { width } = WindowDimensions();
  const isMobile = width < 768;
  const navigate = useNavigate();

  const { filtredUserData, isAuthenticated, logout } = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const linkToProfile = () => {
    navigate('/profile');
    setAnchorEl(null);
  };

  const linkToOrder = () => {
    navigate('/profile/order-history');
    setAnchorEl(null);
  };

  const linkToFavorites = () => {
    navigate('/profile/favorites');
    setAnchorEl(null);
  };

  const [openSearch, setOpenSearch] = React.useState(false);
  const handleOpenSearch = () => {
    if (isMobile) {
      dispatch(openModal('search-modal'));
    } else setOpenSearch(true);
  };
  const handleCloseSearch = () => {
    setOpenSearch(false);
  };

  const [search, setSearchText] = useState('');
  useDebounce(
    () => {
      search.length > 2 &&
        dispatch(searchProducts({ query: search })).then((res) => {
          // if (res?.error) {
          //   toast.error(res?.error?.message);
          //   return;
          // }
        });
    },
    1000,
    [search]
  );
  const { allSearchedProducts } = useSelector((state) => state.products);

  const searchCardRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!searchCardRef?.current?.contains(event.target)) {
        setOpenSearch(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchCardRef]);

  const handleLogout = () => {
    logout();
    handleClose();
    toast.success('déconnecté avec succès');
  };
  const handleLogin = () => {
    navigate('/sign-in');
    handleClose();
  };

  useEffect(() => {
    localStorage.getItem('tokenValue') &&
      dispatch(getBasket()).then(
        (res) => res.meta.requestStatus === 'rejected' && localStorage.removeItem('tokenValue')
      );
  }, []);

  const { basket } = useSelector((state) => state.order);

  useEffect(() => {
    if (openSearch && search.length !== 0) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [openSearch, search]);

  const location = useLocation();

  useEffect(() => {
    dispatch(getSocialMediaItems());
    dispatch(getMenuItems());
  }, []);

  const socialMediaItems = useSelector((state) => state.home.socialMediaItems);
  const menuItems = useSelector((state) => state.home.menuItems);

  const [listWithChildren, setListWithChildren] = useState([]);
  const [listWithoutChildren, setListWithoutChildren] = useState([]);
  const [sortedMenuChildren, setSortedMenuChildren] = useState([]);

  useEffect(() => {
    let listWithItems = [];
    let listWithoutItems = [];
    let sortedMenuItems = [];

    sortedMenuItems = menuItems.firstLevelItems
      ?.slice()
      .sort((i1, i2) => (i1.position < i2.position ? -1 : 1));

    menuItems.firstLevelItems?.map((menuItem) =>
      menuItem.items.length === 0 ? listWithoutItems.push(menuItem) : listWithItems.push(menuItem)
    );

    setSortedMenuChildren(sortedMenuItems);
    setListWithoutChildren(listWithoutItems);
    setListWithChildren(listWithItems);
  }, [menuItems]);

  return (
    <div className="header">
      {!isMobile && (
        <div className="top-header">
          <div className="container top-header-wrapper">
            <div className="social-media">
              {socialMediaItems?.map((socialItem, index) => {
                return (
                  <div key={index}>
                    {
                      {
                        'Facebook': (
                          <Link to={socialItem.url} target="_blank">
                            <img src={Facebook} alt={socialItem.name} className="social-icons" />
                          </Link>
                        ),
                        'Instagram': (
                          <Link to={socialItem.url} target="_blank">
                            <img src={Instagram} alt={socialItem.name} className="social-icons" />
                          </Link>
                        ),
                        'Twitter': (
                          <Link to={socialItem.url} target="_blank">
                            <img src={Twitter} alt={socialItem.name} className="social-icons" />
                          </Link>
                        ),
                      }[socialItem.name]
                    }
                  </div>
                );
              })}
            </div>
            <div className="client-service">
              <img src={phone} alt="phone" />
              {socialMediaItems?.map((socialItem, index) => {
                return <span key={index}> {socialItem.slug === 'phone' && socialItem.name}</span>;
              })}
            </div>
            <div className="navigation">
              <Link to="e-catalogue">
                <p>E-catalogue</p>
              </Link>
              <Link to="carte-cadeau">
                <p>Carte cadeau</p>
              </Link>
              <Link to="aidez-moi">
                <p>Aidez-moi</p>
              </Link>
            </div>
          </div>
        </div>
      )}
      <div className="middle-header">
        <div className="container middle-header-wrapper">
          <div className="logo-wrapper">
            {isMobile && (
              <div className="menu" onClick={() => dispatch(openModal('menu-modal'))}>
                <img src={menu} alt="menu" />
              </div>
            )}
            <div className="logo">
              <Link to="/">
                <img className="logo-img" src={logo} alt="logo" />
              </Link>
            </div>
          </div>
          {(location?.pathname === '/' || !isMobile) && (
            <div ref={searchCardRef} className="search" onClick={handleOpenSearch}>
              <input
                type="search"
                name="search"
                placeholder="Rechercher..."
                autoComplete="off"
                value={search}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                disabled={isMobile}
              />
              <div className="search-icon">
                <img src={searchIcon} alt="search" />
              </div>
              <div className={`search-result ${openSearch && search.length > 2 && 'active'}`}>
                <div className="search-result__cards">
                  {allSearchedProducts?.length > 0 ? (
                    allSearchedProducts?.map((product, index) => (
                      <ProductSearchCard
                        key={index}
                        data={product}
                        handleCloseModalDesktop={() => setSearchText('')}
                      />
                    ))
                  ) : (
                    <p>Aucun produit trouvé</p>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="actions-wrapper__middle-header">
            {location?.pathname !== '/' && isMobile && (
              <div
                className="action__middle-header"
                onClick={() => dispatch(openModal('search-modal'))}
              >
                <img src={searchIcon} alt="search" />
              </div>
            )}
            <Link to="/shops">
              <div className="action__middle-header">
                <img src={locationImg} alt="location" />
                <p>Magasins</p>
              </div>
            </Link>
            <div>
              <button
                className="action__middle-header"
                onClick={isMobile ? () => navigate('/profile') : handleClick}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <img src={userCertification} alt="user certification" />
                <p>
                  {filtredUserData
                    ? filtredUserData.firstName + ' ' + filtredUserData.lastName
                    : 'Profile'}
                </p>
              </button>

              <Menu
                PaperProps={{
                  style: {
                    width: 196,
                    padding: '0 4px',
                    marginTop: 16,
                    boxShadow: '0px 2px 5px #00000029',
                  },
                }}
                id="profile-menu-logged"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                {isAuthenticated && (
                  <MenuItem className="menu-item-header-profile" onClick={linkToProfile}>
                    <img src={userCertification} alt="user certification" />
                    <p className="menu-item-text">Mon compte</p>
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem className="menu-item-header-profile" onClick={linkToOrder}>
                    <img src={commande} alt="user certification" />
                    <p className="menu-item-text">Mes commandes</p>
                  </MenuItem>
                )}
                {isAuthenticated && (
                  <MenuItem className="menu-item-header-profile" onClick={linkToFavorites}>
                    <img src={favoris} alt="user certification" />
                    <p className="menu-item-text">Ma liste favoris</p>
                  </MenuItem>
                )}
                {isAuthenticated && <Divider />}

                <MenuItem
                  className="menu-item-header-profile"
                  onClick={isAuthenticated ? handleLogout : handleLogin}
                >
                  <img src={deconnexion} alt="user certification" />
                  <p className="menu-item-text">
                    {isAuthenticated ? 'Se déconnecter' : 'Se connecter'}
                  </p>
                </MenuItem>
              </Menu>
            </div>

            <div
              className="action__middle-header"
              onClick={() => {
                dispatch(openModal('basket-modal'));
              }}
            >
              <div className="shopping-bag-wrapper">
                <div className="count">{basket?.items?.length || 0}</div>
                <img src={shoppingBag} alt="shopping bag" />
              </div>
              <p>Panier</p>
            </div>
          </div>
        </div>
        {openSearch && search.length > 2 && (
          <div className="search-result-wrapper" onClick={handleCloseSearch}></div>
        )}
      </div>
      {!isMobile && (
        <div className="bottom-header">
          <div className="nav container">
            {/* <div className="nav-wrapper"> */}
            {sortedMenuChildren?.map((listItem, index) =>
              listWithChildren?.includes(listItem) ? (
                <MenuPopoverDesktop
                  key={index}
                  title={listItem.label}
                  image={listItem.images.find((img) => img?.type === 'Desktop')?.fullPath}
                  secondLevelItems={listItem.items}
                  linkFirstLevel={
                    listItem?.translation?.url?.includes('categories')
                      ? '/categories' + listItem.translation?.url?.split('/categories')[1]
                      : listItem.translation?.url
                  }
                />
              ) : listWithoutChildren?.includes(listItem) ? (
                <NavLink
                  key={index}
                  style={StyleToObject(listItem.style)}
                  to={
                    listItem?.translation?.url?.includes('categories')
                      ? '/categories' + listItem?.translation?.url?.split('/categories')[1]
                      : listItem.translation?.url
                  }
                >
                  {listItem.label}
                </NavLink>
              ) : (
                <></>
              )
            )}
            {/* </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
