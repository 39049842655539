import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../utilities/axios';
import axiosForPatch from '../utilities/axiosForPatch';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

const initialState = {
  wishListState: '',
  getWishListState: '',
  customerWishList: {},
  sucessRemoveItem: false,
  sucessAddItem: false,
  currentProductId: '',
  removeProductFromWishListStatus: 'idle',
};

export const getCustomerWishList = createAsyncThunk(
  'wishlist/getCustomerWishList',
  async (thunkAPI) => {
    let data;
    const userToken = window.localStorage.getItem('token');
    const wishListToken = jwtDecode(userToken).wishlist[0].token;
    try {
      const response = await axios.get(`/api/v2/shop/wishlists/${wishListToken}`);
      data = await response.data;
      if (response.status === 200) {
        return data;
      }

      throw new Error(response.statusText);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addProductToWishList = createAsyncThunk(
  'wishlist/addProductToWishList',
  async (productId, thunkAPI) => {
    let data;
    const token = window.localStorage.getItem('token');

    const headers = {
      'Content-Type': 'application/merge-patch+json',
      'Accept': 'application/json',
    };

    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const wishListToken = jwtDecode(token).wishlist[0].token;

    try {
      const response = await axiosForPatch.patch(
        `/api/v2/shop/wishlists/${wishListToken}/product`,
        { productId: productId }
      );
      data = await response.data;
      let sucessAddItem = true;

      let message = toast.success('Le produit est ajouté aux favoris avec succès');
      if (response.status === 200 || 204 || 201) {
        return { data, message, sucessAddItem };
      }
      throw new Error(response.statusText);
    } catch (error) {
      return thunkAPI.rejectWithValue(data?.message);
    }
  }
);

export const removeProductFromWishList = createAsyncThunk(
  'wishlist/removeProductFromWishList',
  async (productId, thunkAPI) => {
    let data;
    const userToken = window.localStorage.getItem('token');
    const wishListToken = jwtDecode(userToken).wishlist[0].token;
    try {
      const response = await axios.delete(
        `/api/v2/shop/wishlists/${wishListToken}/products/${productId}`
      );
      data = await response.data;
      let message = toast.success('Le produit a été supprimé des favoris');
      let sucessRemoveItem = true;
      if (response.status === 200 || 204 || 201) {
        return { data, message, sucessRemoveItem };
      }
      throw new Error(response.statusText);
    } catch (error) {
      return thunkAPI.rejectWithValue(data?.message);
    }
  }
);

const slice = createSlice({
  name: 'wishList',
  initialState,
  reducers: {
    getCurrentProductIdFromWishList: (state, action) => {
      state.currentProductId = action.payload;
    },
    ResetSuccess: (state) => {
      state.sucessRemoveItem = false;
      state.sucessAddItem = false;
    },
    setNewWishList: (state) => {
      let wishListProducts = state.customerWishList.wishlistProducts.filter(
        (product) => product.product.id !== state.currentProductId
      );
      state.customerWishList.wishlistProducts = wishListProducts;
      state.currentProductId = '';
    },
  },
  extraReducers: {
    [getCustomerWishList.pending]: (state) => {
      state.getWishListState = 'loading';
    },
    [getCustomerWishList.fulfilled]: (state, action) => {
      state.getWishListState = 'succeeded';
      state.customerWishList = action.payload;
    },
    [getCustomerWishList.rejected]: (state) => {
      state.getWishListState = 'failed';
    },
    [addProductToWishList.pending]: (state) => {
      state.wishListState = 'loading';
    },
    [addProductToWishList.fulfilled]: (state, action) => {
      state.wishListState = 'succeeded';
      state.sucessAddItem = action.payload.sucessAddItem;
    },
    [addProductToWishList.rejected]: (state) => {
      state.wishListState = 'failed';
    },
    [removeProductFromWishList.pending]: (state) => {
      state.removeProductFromWishListStatus = 'loading';
    },
    [removeProductFromWishList.fulfilled]: (state, action) => {
      state.removeProductFromWishListStatus = 'succeeded';
      state.sucessRemoveItem = action.payload.sucessRemoveItem;
    },
    [removeProductFromWishList.rejected]: (state) => {
      state.removeProductFromWishListStatus = 'failed';
    },
  },
});

export const reducer = slice.reducer;
export const { ResetSuccess, setNewWishList, getCurrentProductIdFromWishList } = slice.actions;

export default slice;
