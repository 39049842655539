import { Rating } from '@mui/material';
import React from 'react';

function RatingStar({
  name,
  defaultValue,
  precision,
  readOnly,
  size,
  color,
  value,
  setValue,
  filledColor,
}) {
  return (
    <Rating
      name={name}
      defaultValue={defaultValue}
      precision={precision}
      readOnly={readOnly}
      size={size}
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      // emptyIcon={<StarBorder fontSize="inherit" />}
      sx={{
        '& .MuiRating-iconFilled': {
          color: filledColor ? filledColor : color,
        },
        '& .MuiRating-iconHover': {
          color: filledColor ? filledColor : color,
        },
        '& .MuiRating-decimal:hover': {
          transform: 'scale(1.2)',
        },
        '& .MuiRating-iconEmpty': {
          color: color,
        },
      }}
    />
  );
}

export default RatingStar;
