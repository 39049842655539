import React from 'react';
import { useDispatch } from 'react-redux';
import { getQuery } from '../../slices/addresses';
import Loader from '../Loader';
import './_index.scss';

const Button = (props) => {
  const {
    text,
    className,
    icon,
    isLoading,
    isLoadingWithSkeleton,
    id,
    onClick,
    latestValue,
    ...rest
  } = props;
  const dispatch = useDispatch();

  return (
    <button
      onClick={onClick}
      className={
        isLoadingWithSkeleton ? `button loading-skeleton ${className}` : `button ${className}`
      }
      {...rest}
    >
      {!isLoading && icon && <img src={icon} alt="" />}
      <span>{isLoading ? <Loader /> : text}</span>
    </button>
  );
};
export default Button;
