import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import GroupMenuDesktop from '../GroupMenuDesktop';
import './_index.scss';

function MenuPopoverDesktop({ title, secondLevelItems, image, linkFirstLevel }) {
  const dispatch = useDispatch();
  const [isHover, setIsHover] = useState(false);
  const [data, setData] = useState('');

  const onMouseEnterHandler = (data) => {
    setIsHover(true);
    setData(data);
  };
  const onMouseLeaveHandler = () => {
    setIsHover(false);
  };
  const [sortSecondLevelItems, setSortSecondLevelItems] = useState();

  useEffect(() => {
    let thirdItems = [];
    thirdItems = secondLevelItems?.slice().sort((i1, i2) => (i1.position < i2.position ? -1 : 1));
    setSortSecondLevelItems(thirdItems);
  }, [secondLevelItems]);
  return (
    <div
      className="menu-popover-desktop"
      onMouseLeave={() => onMouseLeaveHandler('')}
      onMouseEnter={() => onMouseEnterHandler(title)}
    >
      <NavLink
        onClick={() => {
          onMouseLeaveHandler();
        }}
        className="title-first-level"
        to={title !== 'Marques' && `${linkFirstLevel ? linkFirstLevel : ''}`}
      >
        {title}
      </NavLink>
      <div className={`menu-modal ${isHover && 'hover-menu'}`}>
        {title !== 'Marques' ? (
          <div className="menu-modal-content with-image">
            {sortSecondLevelItems?.map((secondLevelItem, index) => {
              return (
                <GroupMenuDesktop
                  key={index}
                  title={secondLevelItem.label}
                  thirdLevelItems={secondLevelItem.items}
                  linkThirdLevel={
                    secondLevelItem?.translation?.url.includes('categories')
                      ? `/categories${secondLevelItem?.translation?.url?.split('categories')[1]}`
                      : secondLevelItem?.translation?.url
                  }
                  onClick={onMouseLeaveHandler}
                />
              );
            })}
            <img src={process.env.REACT_APP_API_URL + image} alt="menu-img" />
          </div>
        ) : (
          <div className="brand-list-wrapper">
            <ul className="list">
              {sortSecondLevelItems?.map((secondLevelItem, index) => {
                return (
                  <li
                    className="list-item"
                    key={index}
                    onClick={() => {
                      onMouseLeaveHandler();
                    }}
                  >
                    <Link
                      to={
                        secondLevelItem?.translation?.url.includes('categories')
                          ? `/categories${
                              secondLevelItem?.translation?.url?.split('categories')[1]
                            }`
                          : secondLevelItem?.translation?.url
                      }
                    >
                      {secondLevelItem.label}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
    // </div>
  );
}

export default MenuPopoverDesktop;
