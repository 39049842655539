import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from '../utilities/axios';

const initialState = {
  createReviewStatus: 'idle',
  createReviewSuccess: '',
  createReviewError: '',
  review: {},
};

export const createReview = createAsyncThunk(
  'reviews/createReview',
  async (reviewData, thunkAPI) => {
    let data;
    try {
      const response = await axios.post(`/api/v2/shop/product-reviews`, reviewData);

      data = await response.data;
      if (response.status === 201) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (error) {
      return Promise.reject(error?.detail ? error?.detail : error?.detail);
    }
  }
);

const slice = createSlice({
  name: 'basket',
  initialState,
  reducers: {},
  extraReducers: {
    [createReview.pending]: (state) => {
      state.createReviewStatus = 'loading';
    },
    [createReview.fulfilled]: (state, action) => {
      state.createReviewStatus = 'succeeded';
      state.review = action.payload;
    },
    [createReview.rejected]: (state, action) => {
      state.createReviewStatus = 'failed';
      state.createReviewError = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export default slice;
