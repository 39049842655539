import React, { useState } from 'react';

import { Box, IconButton, Modal } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import RatingStar from '../../RatingStar';
import Input from '../../Form/Input';
import { createReview } from '../../../slices/reviews';
import useAuth from '../../../hooks/useAuth';
import Textarea from '../../Form/Textarea';
import CustomGroupRadio from '../../Form/CustomGroupRadio';
import Button from '../../Button';
import close from './../../../assets/img/icons/close.svg';
import { toast } from 'react-toastify';
import { closeModal } from '../../../slices/modals';
import './_index.scss';

function ReviewModal({ id, open, handleClose, data }) {
  const { product } = useSelector((state) => state.products);
  const { createReviewStatus } = useSelector((state) => state.reviews);

  const { filtredUser } = useAuth();

  const dispatch = useDispatch();
  const [ratingStar, setRatingStar] = useState(5);
  const initialValues = {
    title: '',
    comment: '',
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Le titre est obligatoire!'),
    comment: Yup.string().required('Le Commentaire est obligatoire!'),
  });

  const onSubmit = (values, { resetForm }) => {
    if (createReviewStatus === 'loading') {
      return;
    }
    dispatch(
      createReview({
        ...values,
        rating: ratingStar,
        product: product.code,
        email: filtredUser?.email,
      })
    ).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.message);
        return;
      }
      toast.success('Votre avis est envoyée avec succes');
      resetForm();
      dispatch(closeModal('review-modal'));
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: '3px 6px 20px #00000029',
    border: 'none',
  };
  const [value, setValue] = useState('oui');
  const radioArray = [
    { value: 'oui', label: 'Oui' },
    { value: 'non', label: 'Non' },
  ];

  return (
    <Modal
      open={open}
      onClose={() => handleClose(id)}
      aria-labelledby="review-modal-title"
      aria-describedby="review-modal-description"
      className="review-modal"
      disableAutoFocus={true}
    >
      <Box sx={style}>
        <div className="modal-review-wrapper">
          <IconButton className="close" onClick={() => handleClose(id)}>
            <img src={close} alt="close" />
          </IconButton>
          <div className="modal-header">
            <p className="modal-title">DONNER MON AVIS</p>
            <p className="product-name">ADIDAS U_PATH RUN</p>
          </div>
          <div className="modal-content">
            <div className="product-rate">
              <p className="product-rate-title">Noter le produit :</p>
              <RatingStar
                name="half-rating-read"
                defaultValue={1}
                precision={1}
                readOnly={false}
                size="large"
                color="#707070"
                filledColor="#FFDB29"
                value={ratingStar}
                setValue={setRatingStar}
              />
              {/* {errorRatingStar && <p>error</p>} */}
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formik) => (
                <Form className="review-modal-form">
                  <Field
                    label="Donner un titre a votre avis :"
                    name="title"
                    component={Input}
                    placeholder="Titre"
                  />

                  <Field
                    label="Ecrire votre commentaire :"
                    name="comment"
                    component={Textarea}
                    placeholder="Dites nous ce que vous pensez de ce produit"
                  />
                  <CustomGroupRadio
                    value={value}
                    setValue={setValue}
                    radioArray={radioArray}
                    label="Recommandez-vous ce produit ?"
                  />
                  <div className="review-modal-btn-wrapper">
                    <Button
                      text="Soumettre mon avis"
                      type="submit"
                      className="review-modal-btn"
                      isLoading={createReviewStatus === 'loading'}
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Box>
    </Modal>
  );
}

export default ReviewModal;
