import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Drawer, IconButton } from '@mui/material';
import ProductSearchCard from '../../ProductSearchCard';
import { searchProducts } from '../../../slices/products';
import close from './../../../assets/img/icons/close.svg';

import './_index.scss';
import useDebounce from '../../../hooks/useDebounce';

function SearchModal({ id, open, handleClose, data }) {
  const dispatch = useDispatch();
  const { allSearchedProducts } = useSelector((state) => state.products);

  const [search, setSearchText] = useState('');

  useDebounce(
    () => {
      search.length > 2 &&
        dispatch(searchProducts({ query: search })).then((res) => {
          // if (res?.error) {
          //   toast.error(res?.error?.message);
          //   return;
          // }
          // toast.success('Quantité changé avec succès');
        });
    },
    1000,
    [search]
  );

  const [openSearch, setOpenSearch] = React.useState(false);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => {
        handleClose(id);
      }}
      className="modal"
    >
      <div className="search-modal-wrapper">
        <div className="search-modal-top">
          <div className="modal-header">
            <p className="modal-title">Recherche</p>
            <IconButton className="close" onClick={() => handleClose(id)}>
              <img src={close} alt="close" />
            </IconButton>
          </div>
          <div className="modal-content">
            <input
              type="search"
              name="search"
              placeholder="Rechercher..."
              autoComplete="off"
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
            <div className={`search-result`}>
              {search.length > 2 && (
                <div className="search-result__cards">
                  {allSearchedProducts?.map((product, index) => (
                    <ProductSearchCard key={index} data={product} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default SearchModal;
